<template>
  <div>
    <breadcrumb
      current-page="Bookmarks" />
    <div class="bookmarks">
      <div class="card-base">
        <div class="card-head-bar">
          <div class="head-title">
            <h1>Bookmarks</h1>
            <sub>View or download your bookmarked results below</sub>
          </div>
        </div>
        <date-separator
          :is-fetching="$actions.pending('search/fetchBookmarks')"
          :items="bookmarks"
          empty-message="No bookmarks available."
          date="dateSaved">
          <el-collapse
            slot="items"
            slot-scope="data"
            v-model="bookmarksCollapse"
            class="plain">
            <bookmark-collapse
              v-for="item in data.items"
              :key="item.id"
              :item="item"
              @delete="clearBookmarksCollapse" />
          </el-collapse>
        </date-separator>
        <div
          v-loading="$actions.pending('search/fetchMoreBookmarks')"
          v-if="bookmarks.length"
          class="item-loader">
          <div
            v-if="lastRow"
            class="end-of-list">
            You have reached the end of the list.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Breadcrumb from '~/components/common/Breadcrumb'
import DateSeparator from '~/components/common/DateSeparator'
import BookmarkCollapse from '~/components/collapse-items/BookmarkCollapse'
import hideSidebarMixin from '~/mixins/hideSidebar'

export default {
  name: 'Bookmarks',
  head() {
    return {
      title: 'Bookmarks - ' + ISNG_TITLE /*eslint-disable-line no-undef */
    }
  },
  components: {
    Breadcrumb,
    DateSeparator,
    BookmarkCollapse
  },
  mixins: [hideSidebarMixin],
  data() {
    return {
      bookmarksCollapse: []
    }
  },
  computed: {
    ...mapState('search', [
      'bookmarks',
      'bookmarkMinDate',
      'bookmarkMaxDate',
      'bookmarkDateRange',
      'lastRow'
    ]),
    dateRangePresets() {
      return [
        ...this.$date.getCommonDateRanges(this.bookmarkMaxDate),
        {
          label: 'ALL',
          text: 'ALL',
          from: this.bookmarkMinDate,
          to: this.bookmarkMaxDate
        }
      ]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scroll)
  },
  destroyed() {
    document.removeEventListener('scroll', this.scroll)
  },
  methods: {
    clearBookmarksCollapse() {
      this.bookmarksCollapse = []
    },
    ...mapActions('search', ['setBookmarkDateRange', 'fetchMoreBookmarks']),
    changeDateRange(dateRange) {
      this.setBookmarkDateRange(dateRange)
    },
    async scroll() {
      if (
        (window.innerHeight + window.scrollY || window.pageYOffset) >=
          document.body.offsetHeight &&
        !this.$actions.pending('search/fetchBookmarks') &&
        !this.$actions.pending('search/fetchMoreBookmarks') &&
        !this.lastRow
      ) {
        await this.fetchMoreBookmarks()
      }
    }
  },
  async fetch({ store, route }) {
    const promise = store.dispatch('search/initialize', route)

    if (!store.state.isMounted) {
      await promise
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/breadcrumbs.scss"/>
<style lang="scss" src="~/assets/scss/sections/download/download-history.scss" />
<style lang="scss" src="~/assets/scss/components/data-viewer/data-collapse-viewer.scss" />
