<template>
  <div class="row-item">
    <el-collapse-item :name="item.bookmarkId">
      <div
        slot="title"
        class="single-column-item">
        <div class="flex-item file-name ellipses">
          <div
            v-click-outside="save"
            v-if="isEditing"
            class="edit-item">
            <input
              ref="input"
              v-model="name"
              :class="{ error: maxCharReached }"
              type="text"
              @paste.stop="validatePaste"
              @keypress.stop="validateKeypress"
              @keyup.enter.stop="save"
              @keyup.escape.stop="cancel"
              @keyup.space.stop>
            <span
              class="removed-icon"
              @click.stop="cancel" />
          </div>
          <strong v-else>{{ item.name }}</strong>
          <i
            v-if="!isEditing"
            class="edit-icon"
            @click.stop="editMode" />
          <sub>Name</sub>
        </div>
        <div class="flex-item view-type">
          {{ item.view || 'NOT AVAILABLE' }}
          <sub>View</sub>
        </div>
        <div class="flex-item total-shipments">
          {{ $numeral(item.shipments).format('0,0') }}
          <sub>Total Shipments</sub>
        </div>
        <div class="flex-item total-weight">
          {{ $numeral(item.weight).format('0,0') }}
          <sub>Total Weight (Kg)</sub>
        </div>
        <div class="flex-item container-numbers">
          {{ convertToTwoDigitDecimal(item.containers) }}
          <sub>Total Number of Containers</sub>
        </div>
      </div>
      <div>
        <collapse-criteria :criteria="item.criteria" />
        <div class="criteria-buttons">
          <img
            v-if="item.country"
            :src="require(`~/assets/img/flag/${item.country}.svg`)"
            :alt="item.country"
            class="flag">
          <label
            v-if="item.country">{{ item.country | upperCase }} {{ item.shipmentType | startCase }} Dataset</label>
          <div class="option-buttons flex-container justify-right">
            <el-popover
              ref="bookmarkPopover"
              placement="bottom-end"
              popper-class="pre-delete-popover open with-footer">
              <div class="popover-content">
                <div class="pop-over-default">Are you sure you want to delete this bookmark?</div>
              </div>
              <div class="popover-footer">
                <a
                  class="btn btn-text"
                  @click="closePopover">NO</a>
                <a
                  class="btn btn-text secondary"
                  @click="deleteBookmark">YES</a>
              </div>
              <a
                slot="reference"
                class="btn btn-text secondary">Delete</a>
            </el-popover>
            <!-- <a
              class="btn btn-text secondary"
              @click="searchAgain">View Results Again</a> -->
            <button
              v-show="!hasNoKeyword(item.criteria)"
              class="btn btn-text"
              @click="searchAgain">
              View Results Again
            </button>
            <download-results
              v-show="!hasNoKeyword(item.criteria)"
              :result-limit="item.shipments"
              :item="item"
              type="bookmarks" />
          </div>
        </div>
      </div>
    </el-collapse-item>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { upperCase, startCase } from 'lodash'
import { convertToTwoDigitDecimal } from '~/utils/number'
import { characterLimit } from '~/config'
import CollapseCriteria from './CollapseCriteria'
import DownloadResults from '~/components/data-viewers/DataViewer/DataViewerHeader/DownloadResults'

export default {
  name: 'BookmarkCollapse',
  components: {
    CollapseCriteria,
    DownloadResults
  },
  filters: {
    upperCase,
    startCase
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      name: '',
      maxCharReached: false
    }
  },
  computed: {
    ...mapState('views', ['views', 'activeShipmentsView']),
    ...mapGetters(['currentSearchURL'])
  },
  methods: {
    ...mapMutations('views', ['setActiveShipmentsView']),
    ...mapActions('search', [
      'bookmarkSearchAgain',
      'updateBookmark',
      'removeBookmark'
    ]),
    convertToTwoDigitDecimal,
    closePopover() {
      this.$refs.bookmarkPopover.doClose()
    },
    searchAgain() {
      if (this.hasNoKeyword(this.item.criteria)) return

      this.bookmarkSearchAgain({
        bookmarkId: this.item.bookmarkId,
        searchId: this.item.searchId,
        searchCriteria: this.item.criteria
      })

      // if (this.item.view && this.item.view !== this.activeShipmentsView.name) {
      //   const view = this.views.find(v => v.name === this.item.view)
      //   if (view) {
      //     this.setActiveShipmentsView(view)
      //   }
      // }
    },
    editMode() {
      this.isEditing = true
      this.name = this.item.name
      this.$nextTick(() => {
        this.$refs.input.focus()
        this.$refs.input.select()
      })
    },
    save() {
      this.isEditing = false
      if (!this.name.trim() || this.name.trim() === this.item.name.trim()) {
        return
      }
      this.updateBookmark({ id: this.item.bookmarkId, name: this.name })
    },
    cancel() {
      this.isEditing = false
    },
    deleteBookmark() {
      this.closePopover()
      this.removeBookmark(this.item.bookmarkId)
      this.$emit('delete')
    },
    validateKeypress(event) {
      if (this.name.length >= characterLimit) {
        event.preventDefault()
        this.maxCharReached = true
        setTimeout(() => {
          this.maxCharReached = false
        }, 500)
      }
    },
    validatePaste(event) {
      const pastedText = event.clipboardData.getData('text')
      if (this.name.length + pastedText.length > characterLimit) {
        event.preventDefault()
        this.maxCharReached = true
        setTimeout(() => {
          this.maxCharReached = false
        }, 500)
      }
    },
    hasNoKeyword(criteria) {
      if (
        !criteria.keywords ||
        (Array.isArray(criteria.keywords) && !criteria.keywords.length)
      ) {
        return true
      }

      return false
    }
  }
}
</script>

<style lang='scss'>
.download-results-form {
  .popper__arrow {
    left: 75% !important;
  }
}
</style>
