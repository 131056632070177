import { render, staticRenderFns } from "./bookmarks.vue?vue&type=template&id=5adc2cc0&"
import script from "./bookmarks.vue?vue&type=script&lang=js&"
export * from "./bookmarks.vue?vue&type=script&lang=js&"
import style0 from "~/assets/scss/sections/breadcrumbs.scss?vue&type=style&index=0&lang=scss&"
import style1 from "~/assets/scss/sections/download/download-history.scss?vue&type=style&index=1&lang=scss&"
import style2 from "~/assets/scss/components/data-viewer/data-collapse-viewer.scss?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports